import {Injectable} from '@angular/core';
import {SearchDriver} from './search-driver';

@Injectable({
  providedIn: 'root',
})
export class SearchBarService2 {
  searchDriver!: SearchDriver;

  constructor() {}
}
